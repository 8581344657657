$ie8: true;
$px-only: true;
@import '../main';

/*article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary,
.container {
  box-sizing: border-box;
}*/

html {
  box-sizing: border-box;
  *behavior: url(/scripts/boxsizing.htc);
}

* {
  box-sizing: inherit;
}

header,
.container,
.inline-parent,
.left,
.right {
  box-sizing: border-box;
  *behavior: url(/scripts/boxsizing.htc);
}

/*
.inline-parent,.left,.right {
  display: inline-block;
  *zoom:1;
  *display: inline;
}
*/
