// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
//$base-url: '/assets/' !default;
$base-url: '/img/' !default;

@import '~bourbon/core/bourbon';
@import '~scut/dist/scut';

@import 'colors';
@import 'typography';

$scut-rem-base: $font-size-base-px;
$scut-em-base: $font-size-base-px;

$border-radius: 3px;
$border-width: scut-em(1px);

$dot-width: scut-em(10);

@import 'breakpoints';

:export {
  //baseline: strip-unit($html-line-height);
  //breakpoints: $breakpoints;
}
